<template>
    <Layout  style="background-color: #e9ecef;height: 1000px">
        <PageHeader :title="title" />
        <hr style="margin-top: -1rem;height: 1px;"/>
        <div style="background-color: white;min-height: 200px;max-height: 800px;overflow:auto">
            <div class="col-12">
                <div class="row mt-4">

                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" >
                            <label class="d-inline-flex align-items-center" style="margin-left: 8px">
                                <b-form-input
                                        v-model="userSearchForm.userAccount"
                                        type="search"
                                        placeholder="用户名/邮件"
                                        class="form-control form-control-sm ml-2"
                                ></b-form-input>
                                <!--                                <select class="form-control" style="float: left;width:70%" v-model="form.typeId" :class="{ 'is-invalid': submitform && $v.form.typeId.$error }">-->
                                <!--                                    <option  v-for="type in typeList" :key="type.id" :value="type.id">{{type.typeName}}</option>-->
                                <!--                                </select>-->
                                <div class="icon-demo-content">
                                    <div class="col-lg-4" style="margin-top: 0px;float: left">
                                        <i class="ri-search-line" title="搜索" @click="searchData"></i>
                                    </div>
                                    <div class="col-lg-4" style="margin-top: 0px;margin-left:15px;float: left">
                                        <i class="ri-close-circle-line" title="清空" @click="clearCondition"></i>
                                    </div>
                                </div>
                                <div class="icon-demo-content">

                                </div>
                            </label>
                        </div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-body">

                            <div class="table-responsive">
                                <table class="table table-hover mb-0">
                                    <thead>
                                    <tr >
                                        <th>#</th>
                                        <th>用户名</th>
                                        <th>账号</th>
                                        <th>邮件地址</th>
                                        <th>登录IP</th>
                                        <th>登录地</th>
                                        <th>登录时间</th>
                                        <th>操作</th>
                                    </tr>
                                    </thead>
                                    <tbody v-for="(col,index) in userList" v-bind:key="col.id">
                                    <tr >
                                        <th scope="row" >
                                            {{index + 1}}
                                        </th>
                                        <td>
                                            <router-link :to="`/article/${col.id}`">
                                                <div class="feature-title"><a>{{col.nikename}}</a></div>
                                            </router-link>
                                        </td>
                                        <td>{{col.userAccount}}</td>
                                        <td>{{col.email}}</td>
                                        <td>{{col.loginIp}}</td>
                                        <td>[{{col.loginProvince + ' ' + col.loginCity }}] </td>
                                        <td>{{col.lastLoginTime | dataFormat4}}</td>
                                        <td >
                                            <div>
                                                <i  v-if="col.enabled == 2" title="启用" class="ri-check-line" style="color: #2ac06d;margin-right: 5px" @click="changeStatus(col,1)"></i>
                                                <i  v-if="col.enabled == 1" title="禁用" class="ri-forbid-2-line" style="color: gray;margin-right: 5px" @click="changeStatus(col,2)"></i>
                                                <i title="编辑" class="ri-edit-line" style="color: #3a8ee6;margin-right: 5px" @click="editUser(col)"></i>
                                                <i title="删除" class="ri-delete-bin-6-line" style="color: red" @click="removeUser(col)"></i>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="col.id == detailId" colspan="24">
                                        <td width="2%">
                                        </td>
                                        <td colspan="10">
                                            <div>
                                                <div style="border: 2px dashed  #eeeeee;float:left;width: 50%;" align="center">
                                                    <div style="margin-top: 10px">
                                                        <h6><b>文章封面</b></h6>
                                                    </div>
                                                    <img :src="picServer+col.titlePage" alt=""
                                                         style="width: 100%;padding: 20px 20px">
                                                </div>
                                                <div style="width: 48%; border: 2px dashed #eeeeee;max-height:500px;overflow:auto;min-height: 100px;float:left;margin-left: 10px" align="center">
                                                    <div style="margin-top: 10px">
                                                        <h6><b>预览</b></h6>
                                                    </div>
                                                    <p v-html="col.userContent">{{col.userContent}}</p>

                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <b-pagination  @change="pageChange($event)" v-model="userSearchForm.pageNo" :total-rows="total" :per-page="userSearchForm.pageSize"></b-pagination>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Swal from "sweetalert2";

    /**
     * Advanced table component
     */
    export default {
        page: {
            title: "文章列表",
            meta: [{ name: "description", content: appConfig.description }]
        },
        components: { Layout, PageHeader },
        data() {
            return {
                picBaseUrl: this.picServer,
                detailId:'',
                typeList:[],
                userList: [],
                showDetail:false,
                title: "用户管理",
                total: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortBy: "age",
                sortDesc: false,
                userSearchForm:{
                    pageNo:1,
                    pageSize:10,
                    userAccount:'',
                },
                userForm:{
                    userTitle: '',
                    userContent: '',
                    titlePage: '',
                    userType: '',
                    summary: '',
                    quoteUrl: '',
                    publishFlag: '',
                    typeId:'',
                    tagIds: [],
                }
            };
        },
        computed: {
            /**
             * Total no. of records
             */
            rows() {
                return this.userList.length;
            }
        },

        methods: {
            pageChange(e){
                this.userSearchForm.pageNo = e ;
                this.getUserList();
                console.log(e)
            },
            openDetail(id) {
                this.detailId = this.detailId == id ? '' : id;
            },
            // 编辑博客
            editUser(user) {
                this.$router.push({
                    path: "/admin/edit_user",
                    query: {user: JSON.stringify(user)}
                })
            },
            clearCondition(){
                this.userSearchForm = {
                    userTitle:'',
                    typeId:'',
                    pageNo: 1,
                    pageSize: 10,
                }
                this.getUserList();

            },
            searchData(){
                this.userSearchForm.pageNo = 1;
                this.userSearchForm.pageSize = 10;
                this.getUserList();
            },
            async getUserList(){
                const {data: res} = await this.$blog.post('/user/selectByPage',this.userSearchForm)
                this.userList = res.data.records;
                this.total = res.data.total
            },
            async getTypeList(){
                // 得到所有的分类
                const {data: res} = await this.$blog.get('/type/getall')
                this.typeList = res.data
            },
            // 启用禁用账号
            async changeStatus (col,enabled){
                let user = col;
                user.enabled = enabled;
                const {data: res} = await this.$blog.post('/user/update',user)
                if (res.code == '200') {
                    this.getUserList();
                    return true;
                }else{
                    return  false;
                }

            },
            removeUser(col) {
                console.log(col)
                Swal.fire({
                    text: "此操作将会删除该账号, 是否继续!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#34c38f",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                }).then(result => {
                    if (result.value) {
                        if (this.changeStatus(col,0)) {
                            Swal.fire("Deleted!", "Your file has been deleted.", "success");
                        }
                    }
                });
            },
            async deleteUser (id){
                const {data: res} = await this.$blog.post('/user/delete/'+id)
                if (res.code == '200') {
                    this.getUserList();
                    return true;
                }else{
                    return  false;
                }

            },

        },
        created() {
            this.getUserList();
            this.getTypeList();
        },
    };
</script>

<style>
    i {
        cursor: pointer;
    }
    .form-control-sm {
        height: calc(1.5em + 0.5rem + 12px);
    }
    a :hover{
        color:#8fd0cc
    }
</style>